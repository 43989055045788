import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	listItem: {
	fontFamily: "'Source Code Pro', monospace",
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.608)', 
	letterSpacing: '2px',
	lineHeight: '12px',
	fontWeight: '400',
	textTransform: 'uppercase'
	},
	homeContainer:{
		backgroundColor: ""
	}
})


class Contact extends Component{

	render(){		
		const {classes} = this.props;
		return(
		<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100vw'}}>

			<div className={classes.listItem}>
				Contact
			</div>
		</div>
		)
	}
}


export default withStyles(styles)(Contact);