import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Route, BrowserRouter, Link, Redirect, Switch } from 'react-router-dom'
import styled, {keyframes} from 'styled-components'

const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	listItem: {
	fontFamily: "'Source Code Pro', monospace",
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.608)', 
	letterSpacing: '2px',
	lineHeight: '12px',
	fontWeight: '400',
	textTransform: 'uppercase'
	},
	homeContainer:{
		backgroundColor: ""
	},
	headerFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '60px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '7px',
		lineHeight: '60px',
		fontWeight: '400',
		textTransform: 'uppercase'
	},
	contactFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '34px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '2px',
		lineHeight: '34.18px',
		fontWeight: '400'
	},
	bodyFont: {
		fontFamily: "'Source Code Pro', monospace",
		fontSize: '15px',
		color: 'rgba(255, 252, 250, 1)', 
		letterSpacing: '1.32px',
		lineHeight: '19.5px',
		fontWeight: '300'
	}
})

const StyledLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const Circle = styled.div`
height: 40px;
width: 40px;
border-radius: 40px;
background-color: white;
cursor: pointer;
&:hover{
  background-color: #7ffff9;
}
`
const MenuItem = styled.div`
border-bottom: none;
padding: 8px;
cursor: pointer;
&:hover{
  border-bottom: 1px solid #7ffff9;
}
`

class Navbar extends Component{

	render(){		
		const {classes} = this.props;
		return(
		<div style={{position: 'sticky', top: 0, backgroundColor: 'black', width: '100%'}}>

			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
					
					<div className={classes.headerFont} style={{padding: 8}}>
						JAK SHILOH
					</div>
				
			</div>


			<div style={{padding: 8, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
				
				<StyledLink to="/">
					<div className={classes.listItem}>
						<MenuItem>
						Home
						</MenuItem>
					</div>
				</StyledLink>

				<StyledLink to="/about">
				<div className={classes.listItem}>
					<MenuItem>
					About
					</MenuItem>
				</div>
				</StyledLink>

				<StyledLink to="/services">
				<div className={classes.listItem}>
					<MenuItem>
					Services
					</MenuItem>
				</div>
				</StyledLink>

				<div>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>

						<Circle style={{marginLeft: 8}}>
							<a target="_blank" href="http://www.twitter.com/jakshiloh"><img src="iconfinder_Rounded_Twitter5_svg_5282551.png" height={40} width={40}/></a>
						</Circle>

						<Circle style={{marginLeft: 8}}>
							<a target="_blank" href="http://www.instagram.com/jakshiloh"><img src="iconfinder_Rounded_Instagram_svg_5282544.png" height={40} width={40}/></a>
						</Circle>

						<Circle style={{marginLeft: 8}}>
							<a target="_blank" href="http://www.youtube.com/jakshiloh"><img src="iconfinder_Rounded_Youtube3_svg_5282548.png" height={40} width={40}/></a>
						</Circle>

						<Circle style={{marginLeft: 8}}>
							<a target="_blank" href="https://soundcloud.com/user-475447377"><img src="soundcloudLogo.png" height={40} width={40}/></a>
						</Circle>

					</div>
				</div>

			</div>
		</div>
		)
	}
}


export default withStyles(styles)(Navbar);