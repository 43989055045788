import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from "react-player"
import Grid from '@material-ui/core/Grid';

const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	listItem: {
	fontFamily: "'Source Code Pro', monospace",
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.608)', 
	letterSpacing: '2px',
	lineHeight: '12px',
	fontWeight: '400',
	textTransform: 'uppercase'
	},
	headerFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '60px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '7px',
		lineHeight: '60px',
		fontWeight: '400',
		textTransform: 'uppercase'
	},
	contactFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '34px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '2px',
		lineHeight: '34.18px',
		fontWeight: '400'
	},
	bodyFont: {
		fontFamily: "'Source Code Pro', monospace",
		fontSize: '15px',
		color: 'rgba(255, 252, 250, 1)', 
		letterSpacing: '1.32px',
		lineHeight: '19.5px',
		fontWeight: '300'
	},
	homeContainer:{
		backgroundColor: ""
	}
})


class AboutMobile extends Component{

	render(){		
		const {classes} = this.props;
		return(

		<div style={{marginLeft: 24, marginRight: 24}}>


			<div style={{marginTop: 24, display: 'flex', flexDirection: 'row'}}>


				<div style={{marginTop: 24, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
			

					<div style={{display: 'flex', flexDirection: 'row'}} className={classes.contactFont}>
						AKHIL
					</div>

					<div style={{display: 'flex', flexDirection: 'row', marginTop: 8}} className={classes.bodyFont}>
						WRITER | PRODUCER | MULTI-INSTRUMENTALIST  

						
					</div>

					<div style={{display: 'flex', flexDirection: 'row', marginTop: 2, marginBottom: 24}} className={classes.bodyFont}>
						
						<span style={{marginLeft: 4}}>
							<a
								href="mailto:jakshilohakhil@gmail.com"
								target="_blank">
								Email
							</a>
						</span>
						 | 
						<span style={{marginLeft: 4}}>
							<a
								href="https://www.linkedin.com/in/akhil-pendalwar-473596b6/"
								target="_blank">
							LINKEDIN
							</a>
						</span>
					</div>

					<div style={{display: 'flex', flexDirection: 'row', marginTop: 8, width: '100%'}}>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div>
									<div style={{height: '60vh', 
										width: '100%', 
										backgroundImage: "url('AKHILBIO.JPG')",
										backgroundRepeat: 'none', 
										backgroundSize: 'cover'
									}}/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={classes.bodyFont} style={{padding: 16}}>
									Akhil Pendalwar is an Indian-American music producer, songwriter, and mixing engineer from Atlanta, GA. He grew up listening to pop music on the radio and began producing music with Josh Pendalwar at age 14. Inspired by artists like Taylor Swift, Katy Perry, and Ellie Goulding, he would stay up all night listening to instrumentals to learn what makes great songs really stand out. He is known for his emphasis in blending ambient and lush sounds in popular music and creating a unique masterpiece for every artist.
								</div>
							</Grid>
						</Grid>
					</div>

				</div>
			</div>

			<div style={{marginTop: 24}}>
				<div style={{height: '1px', backgroundColor: 'grey', width: '100%'}}/>
			</div>

			<div style={{marginTop: 24, display: 'flex', flexDirection: 'row'}}>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
			

					<div style={{display: 'flex', flexDirection: 'row'}} className={classes.contactFont}>
						JOSH
					</div>

					<div style={{display: 'flex', flexDirection: 'row', marginTop: 8}} className={classes.bodyFont}>
						WRITER | PRODUCER | MULTI-INSTRUMENTALIST
							
					</div>

					<div style={{display: 'flex', flexDirection: 'row', marginTop: 4,marginBottom: 24}} className={classes.bodyFont}>
						<span style={{marginLeft: 8}}>
							<a
								href="mailto:jakshiloh@gmail.com"
								target="_blank">
								Email
							</a>
						</span>
						 | 
						<span style={{marginLeft: 8}}>
							<a
								href="https://www.linkedin.com/in/josh-pendalwar-99a281189/"
								target="_blank">
							LINKEDIN
							</a>
						</span>
					</div>


					<div style={{display: 'flex', flexDirection: 'row', marginTop: 8, width: '100%'}}>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div>
									<div style={{height: '60vh', 
										width: '100%', 
										backgroundImage: "url('JoshCover.JPG')",
										backgroundRepeat: 'none', 
										backgroundSize: 'cover'
									}}/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={classes.bodyFont} style={{padding: 24}}>
								

									Taking early influences from the likes of Jimi Hendrix, The Beatles, and Daft Punk, while introducing classical training across the violin, guitar, and keyboard, Josh Pendalwar brings a well rounded and multifaceted sound. 

									“I think what’s cool about a great pop song is that it bridges a lot of genres, styles, and influences in a relatable way.” 

								</div>
							</Grid>
						</Grid>
					</div>


				</div>
			</div>

			<div style={{marginTop: 24}}>
				<div style={{height: '1px', backgroundColor: 'grey', width: '100%'}}/>
			</div>

			<div style={{marginTop: 24, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
				<div style={{marginTop: 24, display: 'flex', flexDirection: 'row'}} className={classes.contactFont}>
					PROJECTS
				</div>
			</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://soundcloud.com/hazihakani/typical'/>
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://soundcloud.com/rodrigo-blankenship/atd-blanko-x-amazing-ming-prod-darkside' />
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://www.youtube.com/watch?v=nNnC4Q6lE5w' />
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://soundcloud.com/pollyblanco/leave-me-ft-kaylie-lai-prod-by-darkside' />
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://www.youtube.com/watch?v=gaW28bKkmDU' />
				</div>

				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:24}}>
					<ReactPlayer url='https://www.youtube.com/watch?v=S_XpFHKavTE' />
				</div>

				<div style={{marginTop: 48, paddingBottom: 24}}>
					<div className={classes.contactFont}>
						GIVE US A SHOUT
					</div>

					<div style={{marginTop: 24}} className={classes.bodyFont}>
						jakshiloh@gmail.com
					</div>

					<div style={{marginTop: 8}} className={classes.bodyFont}>
						TEXT US: (704) 412-3241‬
					</div>

					<div style={{marginTop: 8}} className={classes.bodyFont}>
						Atlanta | Winston-Salem
					</div>

				</div>

		</div>

		)
	}
}


export default withStyles(styles)(AboutMobile);