import logo from './logo.svg';
import './App.css';
import Home from './Home'

function App() {
  return (
    <div className="App" style={{backgroundColor: 'black', width: '100vw'}}>
      <Home/>
    </div>
  );
}

export default App;
