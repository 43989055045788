import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from "react-player"
import Grid from '@material-ui/core/Grid';


const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	listItem: {
	fontFamily: "'Source Code Pro', monospace",
	fontSize: '12px',
	color: 'rgba(255, 255, 255, 0.608)', 
	letterSpacing: '2px',
	lineHeight: '12px',
	fontWeight: '400',
	textTransform: 'uppercase'
	},
	headerFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '60px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '7px',
		lineHeight: '60px',
		fontWeight: '400',
		textTransform: 'uppercase'
	},
	contactFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '34px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '2px',
		lineHeight: '34.18px',
		fontWeight: '400'
	},
	bodyFont: {
		fontFamily: "'Source Code Pro', monospace",
		fontSize: '15px',
		color: 'rgba(255, 252, 250, 1)', 
		letterSpacing: '1.32px',
		lineHeight: '19.5px',
		fontWeight: '300'
	},
	homeContainer:{
		backgroundColor: ""
	}
})


class ServicesMobile extends Component{

	render(){		
		const {classes} = this.props;
		return(

		<div style={{paddingTop: 24, marginLeft: 24, marginRight: 24}}>

			<div style={{width: '100%'}}>
				<div className={classes.contactFont}>
					SERVICES
				</div>

				<div style={{paddingTop: 24}}>
					<Grid container spacing={8}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<div style={{padding: 8, width: '100%'}}>
								<div className={classes.bodyFont}>
									PRODUCTION
								</div>
								<div style={{marginTop: 8}} className={classes.bodyFont}>
									Want a banger or fresh vibe for your next project? We can help you with original compositions and production. 
								</div>
							</div>
						</Grid> 
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<div style={{padding: 8, width: '100%'}}>
								<div className={classes.bodyFont}>
									SONGWRITING
								</div>

								<div style={{marginTop: 8}} className={classes.bodyFont}>
									We like to write catchy lyrics and arrange songs.
								</div>
							</div>
						</Grid> 
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<div style={{padding: 8, width: '100%'}}>
								<div className={classes.bodyFont}>
									MIXING
								</div>

								<div style={{marginTop: 8}} className={classes.bodyFont}>
									We can help you get a clear and balanced mix ready for mastering.
								</div>
							</div>
						</Grid> 
					</Grid>
				</div>

			</div>

			<div style={{marginTop: 48, paddingBottom: 24}}>
				<div className={classes.contactFont}>
					GIVE US A SHOUT
				</div>

				<div style={{marginTop: 8}} className={classes.bodyFont}>
					jakshiloh@gmail.com
				</div>

				<div style={{marginTop: 8}} className={classes.bodyFont}>
					TEXT US: (704) 412-3241‬
				</div>

			</div>


		</div>

		)
	}
}


export default withStyles(styles)(ServicesMobile);