import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Route, BrowserRouter, Link, Redirect, Switch } from 'react-router-dom'
import Navbar from './Navbar'
import NavbarMobile from './NavbarMobile'
import Homepage from './Homepage';
import Contact from './Contact';
import About from './About';
import Services from './Services'
import Hidden from '@material-ui/core/Hidden';

import HomepageMobile from './HomepageMobile'
import AboutMobile from './AboutMobile'
import ServicesMobile from './ServicesMobile'

const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	title: {
	fontFamily: "'Abel', sans-serif",
	fontSize: '54px',
	color: 'white', 
	letterSpacing: '4px',
	lineHeight: '118.8px'
	},
	homeContainer:{
		backgroundColor: ""
	}
})


class Home extends Component{

	render(){		
		const {classes} = this.props;
		return(
		<div style={{
			height: '100%', 
			minHeight: '100vh', 
			width: '100vw', 
			backgroundColor: colorScheme["main"],
			}}>

			<div style={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			width: '100vw'
			}}>

			<Hidden smDown>
				<div style={{maxWidth: '60vw'}}>
					<BrowserRouter>
						<Navbar/>
						<Switch>
							<Route exact path="/" component={Homepage}/>
							<Route path="/about" component={About}/>
							<Route path="/contact" component={Contact}/>
							<Route path="/services" component={Services}/>
						</Switch>
					</BrowserRouter>
				</div>
			</Hidden>

			<Hidden mdUp>
				<div style={{maxWidth: '100vw'}}>
					<BrowserRouter>
						<NavbarMobile/>
						<Switch>
							<Route exact path="/" component={HomepageMobile}/>
							<Route path="/about" component={AboutMobile}/>
							<Route path="/services" component={ServicesMobile}/>
						</Switch>
					</BrowserRouter>
				</div>
			</Hidden>

			</div>

		</div>
		)
	}
}

export default withStyles(styles)(Home);