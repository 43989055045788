import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const colorScheme = {
	main: 'rgba(0,0,0,1)',
}

const styles = theme => ({
	headerFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '60px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '7px',
		lineHeight: '60px',
		fontWeight: '400',
		textTransform: 'uppercase'
	},
	contactFont: {
		fontFamily: "'Abel', sans-serif",
		fontSize: '34px',
		color: 'rgba(255, 255, 255, 1)', 
		letterSpacing: '2px',
		lineHeight: '34.18px',
		fontWeight: '400'
	},
	bodyFont: {
		fontFamily: "'Source Code Pro', monospace",
		fontSize: '15px',
		color: 'rgba(255, 252, 250, 1)', 
		letterSpacing: '1.32px',
		lineHeight: '19.5px',
		fontWeight: '300'
	},
	homeContainer:{
		backgroundColor: ""
	}
})


class HomepageMobile extends Component{

	render(){		
		const {classes} = this.props;
		return(
		<div style={{marginRight: 24, marginLeft:24}}>
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
				<div style={{height: '60vh', 
				width: '100vw', 
				backgroundImage: "url('BackgroundCover.jpg')",
				backgroundRepeat: 'none', 
				backgroundSize: 'cover'
			}}/>
			</div>


			<div style={{marginTop: 24, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
			
				<div style={{display: 'flex', flexDirection: 'row'}} className={classes.contactFont}>
					FEATURED IN
				</div>
			
			</div>

			<div>
				<Grid container spacing={4}>
					<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
							<a 
							target="_blank"
							href="https://www.ajc.com/sports/georgia-kicker-rodrigo-blanko-blankenship-releases-rap-atd/NRtnkjFE4nJSWqGwKJ8P1L/">
							<div style={{height: '150px', 
								width: '150px',
								backgroundImage: "url('AJC.png')",
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain'
							}}/>
							</a>
						</div>
					</Grid>

					<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
							<a 
							target="_blank"
							href="https://www.redandblack.com/culture/student-releases-r-b-single-with-an-extra-dose-of-sass-confidence/article_1196c102-1ff0-11e7-86bb-37b9238d8cae.html">

							<div style={{height: '150px', 
								width: '150px', 
								backgroundImage: "url('TheRedAndBlack.png')",
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain',

							}}/>
							</a>
						</div>
					</Grid>

					<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
							<a 
							target="_blank"
							href="https://atlanta.consulfrance.org/pourquoi-pas-inspiring-students-to-be-their-best">

							<div style={{
								height: '96px', 
								width: '150px', 
								backgroundImage: "url('franceLogo.png')",
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain'
							}}/>
							</a>
						</div>
					</Grid>

					<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
						<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
							<a 
							target="_blank"
							href="https://www.bizjournals.com/triad/news/2020/09/11/brothers-developed-proodos-and-firefeed-apps.html">

							<div style={{
								height: '96px', 
								width: '150px', 
								backgroundImage: "url('TBJ.png')",
								backgroundRepeat: 'no-repeat', 
								backgroundSize: 'contain'
							}}/>
							</a>
						</div>
					</Grid>			

				</Grid>
			</div>

			<div style={{padding: 8, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center'}}>

				

			</div>


			<div style={{marginTop: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
				<div className={classes.contactFont}>
					WHO WE ARE
				</div>

			</div>

			<div style={{marginTop: 24, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
				<div className={classes.bodyFont}>

					Our focus lies in producing original songs for our clients' campaigns, mixing songs, and songwriting.
				</div>

			</div>


			<div style={{marginTop: 48, paddingBottom: 24}}>
				<div className={classes.contactFont}>
					GIVE US A SHOUT
				</div>

				<div style={{marginTop: 24}} className={classes.bodyFont}>
					jakshiloh@gmail.com
				</div>

				<div style={{marginTop: 8}} className={classes.bodyFont}>
					TEXT US: (704) 412-3241‬
				</div>

				<div style={{marginTop: 8}} className={classes.bodyFont}>
					Atlanta | Winston-Salem
				</div>

			</div>

							
		</div>
		)
	}
}


export default withStyles(styles)(HomepageMobile);